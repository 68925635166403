export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'User',
    icon: 'UserIcon',
    children: [
      {
        title: 'All User',
        route: 'user-all',
        icon: 'UsersIcon',
      },
      {
        title: 'Add User',
        route: 'user-add',
        icon: 'UserPlusIcon',
      },
    ],
  },
  {
    title: 'Church',
    icon: 'TriangleIcon',
    children: [
      {
        title: 'All Church',
        route: 'church-all',
        icon: 'CircleIcon',
      },
      {
        title: 'Add Church',
        route: 'church-add',
        icon: 'CircleIcon',
      },
    ],
  },
  {
    title: 'Synod',
    icon: 'SlackIcon',
    children: [
      {
        title: 'All Synod',
        route: 'synod-all',
        icon: 'CircleIcon',
      },
      {
        title: 'Add Synod',
        route: 'synod-add',
        icon: 'CircleIcon',
      },
    ],
  },
  {
    title: 'Department',
    icon: 'TrelloIcon',
    children: [
      {
        title: 'All Department',
        route: 'department-all',
        icon: 'CircleIcon',
      },
      {
        title: 'Add Department',
        route: 'department-add',
        icon: 'CircleIcon',
      },
    ],
  },
  {
    title: 'Position',
    icon: 'UserCheckIcon',
    children: [
      {
        title: 'All Position',
        route: 'position-all',
        icon: 'CircleIcon',
      },
      {
        title: 'Add Position',
        route: 'position-add',
        icon: 'CircleIcon',
      },
    ],
  },
  {
    title: 'Event',
    icon: 'CalendarIcon',
    children: [
      {
        title: 'All Event',
        route: 'event-all',
        icon: 'CircleIcon',
      },
      {
        title: 'Add Event',
        route: 'event-add',
        icon: 'CircleIcon',
      },
    ],
  },
  {
    title: 'Category',
    icon: 'GridIcon',
    children: [
      {
        title: 'All Category',
        route: 'category-all',
        icon: 'CircleIcon',
      },
      {
        title: 'Add Category',
        route: 'category-add',
        icon: 'CircleIcon',
      },
    ],
  },
  {
    title: 'Article',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'All Article',
        route: 'article-all',
        icon: 'CircleIcon',
      },
      {
        title: 'Add Article',
        route: 'article-add',
        icon: 'CircleIcon',
      },
    ],
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'All Slider',
        route: 'slider-all',
        icon: 'CircleIcon',
      },
    ],
  },
]
